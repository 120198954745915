<template>
  <div>
    <!--begin::Row-->
    <div class="row">
        <div class="col-lg-12">
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                    <h3 class="card-label">Role</h3>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div>
                        <!--begin::Content-->
                        <div><br>
                            <button type="button" class="btn btn-primary" size="sm" style="background-color:#030A8C; border-color: #030A8C" v-b-modal.modal-tambah><i class="flaticon2-plus"></i>Tambah</button>
                            <br><br><div>
                                <div style="overflow-x:auto; overflow-y: hidden;">
                                    <table id="example" class="table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Id Role</th>
                                            <th>Nama</th>
                                            <th>Keterangan</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="row in roles" :key="row.id">
                                            <td></td>
                                            <td>{{row.id}}</td>
                                            <td>{{row.name}}</td>
                                            <td>{{row.ket}}</td>
                                            <td>
                                                <b-button @click="deleterole(row.id)" size="sm" variant="danger"><i class="flaticon2-trash"></i> Hapus</b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>
            <!-- Tambah -->
            <b-modal
                v-model="show"
                id="modal-tambah"
                ref="modal"
                size="md"
                scrollable
                title="Tambah Role"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
                >
                <form ref="form" @submit.stop.prevent="tambahrole">
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">id</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="id" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Nama</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="name" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-lg-4 col-form-label">Keterangan</label>
                        <div class="col-lg-8">
                            <input type="text" v-model="ket" class="form-control"/>
                        </div>
                    </div>
                                    
                </form>
                <template #modal-footer>
                    <div class="w-100">
                    <!-- <p class="float-left">Modal Footer Content</p> -->
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="background-color: #030A8C; border-color: #030A8C; color:white"
                        @click="tambahrole"
                    >
                        Simpan
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px;"
                        @click="show=false"
                    >
                        Tutup
                    </b-button>
                    </div>
                </template>
                </b-modal>

                <!-- modal edit role -->
                <div class="modal fade" id="editrole" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Ubah Role</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">id</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getrole.id" class="form-control"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Nama</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getrole.name" class="form-control"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-lg-4 col-form-label">Keterangan</label>
                                <div class="col-lg-8">
                                    <input type="text" v-model="getrole.ket" class="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            <button type="button" @click="editrole(getrole.id)"  style="background-color: #030A8C; border-color: #030A8C; color:white" data-dismiss="modal" class="btn btn-primary">Simpan</button>
                        </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  </div>
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';

export default {
  name: "dashboard",
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem("id_token"),
        show:false,
        roles:[],
        getrole:[],
        id:'',
        name:'',
        ket:'',
      }
  },
  components: {
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Role" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    loaddata(){
            axios.get(this.url+"/role", {
                headers: {
                    'xth': this.token
                }
            })
            .then(response => {
                this.roles = response.data.data;
                this.initDatatable()
            }).catch(error=>{
                return error
            });

            
    },
    deleterole(id){
        axios
            .delete(
            this.url+`/role/${id}`,
            {
                headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Dihapus', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Dihapus', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    resetModal() {
        this.id = ''
        this.name = ''
        this.ket=''
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.tambahrole()
      },
      tambahrole() {
        const id = this.id;
        const ket = this.ket;
        const name = this.name;
        axios
            .post(
            this.url+"/role",
            {
                id,
                ket,
                name,
            }
            ,{
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){

  }
};
</script>
